// in src/comments.js
import React from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { EditButton, ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, required } from 'react-admin';
import { Table, useTheme, Grid, TableBody, Typography, CardContent, Card, Avatar, Tooltip, ButtonBase, Box } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { OrganizationIcon } from '..';
import { useNavigate } from 'react-router-dom';




const OrganizationCardContent = ({ organization }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>
                    {organization.description && (
                        <CardDetailRow label={translate('resources.organization.description')} ValueComponent={() => <TextField source="description" record={organization} />} />
                    )}
                    {organization.parent_organization_id && (
                        <CardDetailRow label={translate('resources.organization.parent')} ValueComponent={() => <ReferenceField
                            allowEmpty={true}
                            source="parent_organization_id"
                            reference="organizations"
                            record={organization}
                        >
                            <TextField source="name" />
                        </ReferenceField>} />
                    )}
                </TableBody>
            </Table>
        </Box>
    );
}


const OrganizationCardHeader = ({ permissions, organization }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    <Avatar
                        size={"45px"}
                        style={{ backgroundColor: theme.palette.background.default, width: "45px", height: "45px" }}
                    >
                        <OrganizationIcon />
                    </Avatar>
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{organization ? `${organization.name}` : ""}</Typography>
            </Box>
        </Box>

    );
}

const MobileGrid = () => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((organization, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: "0px 0px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <Tooltip title={translate(`resources.organizations.browse_organization`)} disableInteractive >
                                <ButtonBase
                                    onClick={() => { if (hasWriteAccess(permissions)) { navigate(`/organizations/${organization.organization_id}`); } }}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0px',
                                        padding: "0px"
                                    }}
                                >
                                    <OrganizationCardHeader organization={organization} permissions={permissions} />
                                    <OrganizationCardContent sx={{
                                        width: "100%",
                                    }} organization={organization} />

                                </ButtonBase>

                            </Tooltip>
                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    )


}




export default MobileGrid