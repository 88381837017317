// in src/comments.js
import React, { useEffect, useState } from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { useTheme, Chip, CardContent, Card, CardHeader, Grid, Avatar, TableBody, Box, ButtonBase, Tooltip, TableRow, TableCell, Button, TextField as MuiTextField, Typography, Table } from '@mui/material'
import { ChipField, useNotify, ReferenceField, TextField, usePermissions, useTranslate, useListContext, useRefresh, useLocaleState, FunctionField } from 'react-admin';
import { NotificationIcon } from '..';
import { CardDetailRow } from '../../../ui/Common';
import { useNavigate } from 'react-router-dom';
import StandardDialog from '../../../ui/dialog/StandardDialog';
import { textInputCommonStyle } from '../../../themes/commonStyles';
import { getSmsCreditPlans, getUserRemaingSmsCredit, resendCodeForEmailDestination, resendCodeForSmsDestination, smsCreditCheckoutSession, verifyEmailDestination, verifySmsDestination } from '../../../rest/restClient';
import { USER_ID } from '../../configuration/actions';
import CardSmsCreditPlan from './CardSmsCredit';

const NotificationCardContent = ({ notification }) => {
    const translate = useTranslate();
    const subject = translate(`resources.notifications.${notification.subject}`)
    const translatedSubject = subject ?
        subject.length < 25 ?
            subject :
            subject.substring(0, 25) + "..."
        : "";

    return (


        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>
                    {notification.device_id && (
                        <CardDetailRow label={translate('resources.devices.name', { smart_count: 1 })} ValueComponent={() => <ReferenceField
                            allowEmpty={true}
                            source="device_id"
                            reference="devices"
                            record={notification}
                            basePath="devices"
                        >
                            <TextField source="name" />
                        </ReferenceField>} />
                    )}

                    {notification.created_by && (
                        <CardDetailRow label={translate('resources.notifications.fields.created_by', { smart_count: 1 })} ValueComponent={() => <ReferenceField
                            allowEmpty={true}
                            source="created_by"
                            reference="users"
                            record={notification}
                        >
                            <FunctionField
                                render={record => record.first_name && record.last_name ? record.first_name.toUpperCase() + ' ' + record.last_name.toUpperCase() : record.email}
                                sortable={false}
                            />
                        </ReferenceField>} />
                    )}

                    {notification.channel && (
                        <CardDetailRow label={translate('resources.notifications.fields.channel')} ValueComponent={() => <ChipField
                            sx={{ textTransform: 'uppercase' }}
                            source="channel"
                            record={notification}
                        />} />
                    )}
                    {notification.subject && (
                        <CardDetailRow label={translate('resources.notifications.fields.subject')} ValueComponent={() => <Chip
                            sx={{ textTransform: 'uppercase', fontSize: "10px" }}
                            label={translatedSubject}
                            record={notification}
                        />} />
                    )}

                    {notification.destinations && Array.isArray(notification.destinations) && (
                        <CardDetailRow label={translate('resources.notifications.fields.destinations')} ValueComponent={() =>
                            <Box>
                                {
                                    notification.destinations.map((rec, idx) => {
                                        if (idx < 1) return (<ChipField
                                            key={idx}
                                            sx={{ fontSize: "10px" }}
                                            record={rec}
                                            source={notification.channel === "email" ? "email" : "number"}
                                        />)
                                        if (idx === 1) return (<ChipField
                                            key={idx}
                                            sx={{ fontSize: "10px" }}
                                            record={{ v: "..." }}
                                            source={"v"}
                                        />
                                        )
                                    }
                                    )
                                }

                            </Box>
                        }
                        />
                    )}


                </TableBody>
            </Table>
        </Box>
    );
}

const NotificationCardHeader = ({ permissions, notification }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    <Avatar
                        size={"45px"}
                        style={{ backgroundColor: theme.palette.background.default, width: "45px", height: "45px" }}
                    >
                        <NotificationIcon />
                    </Avatar>
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{notification ? `${notification.name}` : ""}</Typography>
            </Box>

        </Box>

    );
}

const notificationIsInactive = (notification) => {
    return (!notification ||
        notification.active !== true)


}
const notificationHasUnverifiedDestination = (notification) => {
    if (notification &&
        notification.destinations &&
        Array.isArray(notification.destinations)
    ) {
        return notification.destinations.filter((d) => d && (d.verified === false)).length > 0
    } else {
        return false
    }

}



const dataHasUnverifiedDestination = (data) => {
    if (data &&
        Array.isArray(data)
    ) {
        return data.filter((n) => notificationHasUnverifiedDestination(n)).length > 0
    } else {
        return false
    }

}




const getNotificationsWithUnverifiedDestinations = (data) => {
    if (dataHasUnverifiedDestination(data)) {
        return data.filter((n) => notificationHasUnverifiedDestination(n))
    } else {
        return false
    }


}

const VerifyDestinationRow = ({ n, dest }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const refresh = useRefresh()
    const [code, setCode] = useState(null)
    const [response, setReponse] = useState(null)
    const mustResendCode = dest && dest.verification_code_expires_at && (new Date(dest.verification_code_expires_at) < new Date())
    const verifyDestination = n.channel === "sms" ? verifySmsDestination : n.channel === "email" ? verifyEmailDestination : null;
    const resendCodeForDestination = n.channel === "sms" ? resendCodeForSmsDestination : n.channel === "email" ? resendCodeForEmailDestination : null;

    const onVerify = () => {
        if (verifyDestination) {
            verifyDestination(dest.id, code).then((reponse) => {
                if (reponse && reponse.status === 200) {
                    setReponse(translate('resources.heading_messages.verified'))
                } else {
                    setReponse(translate('resources.heading_messages.error_verifying'))
                    setTimeout(() => {
                        setReponse(null)
                        setCode(null)
                    }, 3000);
                }
            }
            ).catch((error) => {
                if (error && error.status === 400 && error.message === "Invalid verification code") {
                    setReponse(translate('resources.heading_messages.invalid_code'))
                    setTimeout(() => {
                        setReponse(null)
                        setCode(null)
                    }, 3000);
                } else {
                    setReponse(translate('resources.heading_messages.error_verifying'))
                    setTimeout(() => {
                        setReponse(null)
                        setCode(null)
                    }, 3000);
                }
            }
            )
        }

    }

    const onResend = () => {
        if (resendCodeForDestination) {
            resendCodeForDestination(dest.id).then((reponse) => {
                if (reponse && reponse.status === 200) {
                    setReponse(translate('resources.heading_messages.code_resent'))
                    setTimeout(() => {
                        refresh()
                    }, 1500);
                } else {
                    setReponse(translate('resources.heading_messages.error_resending_code'))
                    setTimeout(() => {
                        setReponse(null)
                        setCode(null)
                    }, 3000);
                }
            }
            ).catch((error) => {
                if (error && error.status === 400 && error.message === "You have reached the maximum number of verification retries") {
                    setReponse(translate('resources.heading_messages.resending_code_limit_reached'))
                } else {
                    setReponse(translate('resources.heading_messages.error_resending_code'))
                    setTimeout(() => {
                        setReponse(null)
                        setCode(null)
                    }, 3000);
                }
            }
            )
        }

    }


    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left" sx={{ padding: "0px 0px 20px 0px", fontWeight: 500 }}>{`${n.channel === "sms" ? dest.number : dest.email} :`}</TableCell>
            <TableCell align="left" sx={{ padding: "0px 0px 0px 10px", fontWeight: 400 }}>
                <MuiTextField
                    sx={textInputCommonStyle({ theme, width: "150px", height: "30px" })}
                    value={code}
                    disabled={mustResendCode}
                    placeholder={translate('resources.heading_messages.code_ask')}
                    onChange={(event) => {
                        setCode(event.target.value);
                    }}
                />
            </TableCell>
            {!response && !mustResendCode &&
                <TableCell align="right" sx={{ padding: "0px 0px 20px 10px", fontWeight: 400 }}><Button disabled={(code && code.length > 3) ? false : true} onClick={onVerify}>{translate('resources.heading_messages.verify')}</Button></TableCell>
            }
            {!response && mustResendCode &&
                <TableCell align="right" sx={{ padding: "0px 0px 20px 10px", fontWeight: 400 }}><Button onClick={onResend}>{translate('resources.heading_messages.resend_code')}</Button></TableCell>
            }
            {response &&
                <TableCell align="left" sx={{ padding: "0px 0px 20px 10px", fontWeight: 400, maxWidth: "100px" }}>{response}</TableCell>
            }
        </TableRow>
    )
}

const CoinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-coins"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z" /><path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4" /><path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z" /><path d="M3 6v10c0 .888 .772 1.45 2 2" /><path d="M3 11c0 .888 .772 1.45 2 2" /></svg>
    )
}
const HourGlassIcon = () => {
    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.innerHTML = shineKeyframes;
        document.head.appendChild(styleElement);
    }, []);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-hourglass-empty" style={{ animation: 'shine 2s ease-in-out infinite', animationDelay: '1s' }}><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z" /><path d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z" /></svg>
    )
}

const shineKeyframes = `
  @keyframes shine {
    0% {
      color: inherit;
    }
    33% {
      color: orange;
    }
    100% {
      color: inherit;
    }
  }
`;

const SmsCreditHeadedMessage = ({ data, isSmall }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const refresh = useRefresh()
    const [locale, setLocale] = useLocaleState();
    const [openSmsCreditDialog, setOpenSmsCreditDialog] = useState(false)
    const [remainingSmsCredit, setRemainingSmsCredit] = useState(null);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const notify = useNotify();
    useEffect(() => {
        const userId = localStorage.getItem(USER_ID);
        getSmsCreditPlans(userId, locale).then((res) => {
            if (res && res.status === 200 &&
                res.json && res.json._embedded) {
                setPlans(res.json._embedded.sms_credit_plans)
            }
        })
    }, [])

    useEffect(() => {
        const userId = localStorage.getItem(USER_ID);
        getUserRemaingSmsCredit(userId).then((res) => {
            if (res && res.status === 200) {
                setRemainingSmsCredit(res.body)
            }
        })
    }, [])

    const handleCheckout = (sPlan) => {
        const smsCreditsPurchase = { plan_id: sPlan.plan_id }
        smsCreditCheckoutSession(smsCreditsPurchase)
            .then((response) => {
                if (response.json && response.json.session_url) {
                    const sessionUrl = response.json.session_url
                    window.open(sessionUrl, "_self");
                } else {
                    notify(translate('resources.subscriptions.checkout_session_error'), 'warning')
                }
            })
            .catch(error => {
                notify(translate('resources.subscriptions.checkout_session_error'), 'warning')
            })

    }

    return (
        <Grid item sm={12} sx={{
            width: "100%",
            padding: "0px 0px 12px 0px"
        }} >
            <Card sx={{
                height: '100%',
                display: 'flex',
                borderRadius: "12px",
                flexDirection: 'column',
                margin: '0px',
            }}>
                <CardContent sx={{
                    ...theme.typography.body1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "space-between",
                    padding: "10px 10px 10px 10px !important"
                }}>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {(!remainingSmsCredit) && <TableCell align="left" sx={{ padding: "0px", fontWeight: 500 }}><HourGlassIcon /></TableCell>}
                            {remainingSmsCredit && <TableCell align="left" sx={{ padding: "0px", fontWeight: 500 }}><CoinIcon /></TableCell>}
                            <TableCell align="left" sx={{ padding: "0px 0px 0px 10px", fontWeight: 500 }}>{`${translate('resources.heading_messages.remaing_credit')} :`}</TableCell>
                            {remainingSmsCredit && [
                                <TableCell align="left" sx={{ padding: "0px 0px 0px 10px", fontWeight: 500 }}>{remainingSmsCredit}</TableCell>,
                                <TableCell align="left" sx={{ padding: "0px 0px 0px 10px", fontWeight: 400 }}>{`${translate('resources.heading_messages.sms')}`}</TableCell>,
                                <TableCell align="right" sx={{ padding: "0px 0px 0px 10px", fontWeight: 400 }}><Button onClick={() => setOpenSmsCreditDialog(true)}>{translate('resources.heading_messages.add_credit')}</Button></TableCell>
                            ]
                            }

                        </TableRow>
                    </TableBody>
                </CardContent>

                <StandardDialog
                    open={openSmsCreditDialog}
                    fullWidth={true}
                    onClose={() => { setOpenSmsCreditDialog(false); }}
                    BodyComponent={() => {

                        return (
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={3} align="left" sx={{ padding: "0px", fontWeight: 500 }}>
                                        <CardSmsCreditPlan
                                            plans={plans}
                                            selectedPlan={selectedPlan}
                                            setSelectedPlan={setSelectedPlan}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={selectedPlan ? 1.5 : 3} align="center" sx={{ padding: "0px", fontWeight: 500 }}><Button onClick={() => { setOpenSmsCreditDialog(false); refresh() }}>{translate('ra.action.close')}</Button></TableCell>
                                    {selectedPlan &&
                                        <TableCell colSpan={1.5} align="center" sx={{ padding: "0px", fontWeight: 500 }}><Button variant='contained' onClick={() => { handleCheckout(selectedPlan); }}>{translate('resources.heading_messages.add_credit')}</Button></TableCell>
                                    }</TableRow>
                            </TableBody>
                        )
                    }}
                />

            </Card>
        </Grid>
    )

}


const DestinationVerificationHeadedMessage = ({ data, isSmall }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const refresh = useRefresh()
    const [openVerificationDialog, setOpenVerificationDialog] = useState(false)

    return (
        <Grid item sm={12} sx={{
            width: "100%",
            padding: "0px 0px 12px 0px"
        }} >
            <Card sx={{
                height: '100%',
                display: 'flex',
                borderRadius: "12px",
                flexDirection: 'column',
                margin: '0px',
            }}>
                <CardContent sx={{
                    ...theme.typography.body1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "space-between",
                    padding: "10px 10px 10px 10px !important"
                }}>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" sx={{ padding: "0px", fontWeight: 500 }}>{`${translate('resources.heading_messages.message')} :`}</TableCell>
                            <TableCell align="left" sx={{ padding: "0px 0px 0px 10px", fontWeight: 400 }}>{`${translate('resources.heading_messages.verify_notification_destinations')}`}</TableCell>
                            <TableCell align="left" sx={{ padding: "0px 0px 0px 10px", fontWeight: 400 }}><Button onClick={() => setOpenVerificationDialog(true)}>{translate('resources.heading_messages.enter_code')}</Button></TableCell>
                        </TableRow>
                    </TableBody>
                </CardContent>

                <StandardDialog
                    open={openVerificationDialog}
                    fullWidth={true}
                    onClose={() => { setOpenVerificationDialog(false); refresh() }}
                    BodyComponent={() => {
                        const notifications = getNotificationsWithUnverifiedDestinations(data)
                        return (
                            <TableBody>
                                {
                                    notifications.map((n, idx) => {
                                        let rows = []
                                        rows.push(
                                            <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell colSpan={3} align="left" sx={{ padding: "0px", fontWeight: 500 }}>{`${n.name} :`}</TableCell>
                                            </TableRow>
                                        )
                                        n.destinations.filter((d) => d && (d.verified === false)).map(((dest, idx2) => {
                                            rows.push(
                                                <VerifyDestinationRow key={idx2 * 50 + 1} n={n} dest={dest} />
                                            )
                                        }
                                        ))

                                        return (rows)
                                    }
                                    )
                                }
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={4} align="center" sx={{ padding: "0px", fontWeight: 500 }}><Button onClick={() => { setOpenVerificationDialog(false); refresh() }}>{translate('ra.action.close')}</Button></TableCell>
                                </TableRow>
                            </TableBody>
                        )
                    }}
                />

            </Card>
        </Grid>
    )
}


const MobileGridHeadedMessage = ({ data, isSmall }) => {
    const messages = []
    messages.push(<SmsCreditHeadedMessage data={data} isSmall={isSmall} />)
    if (dataHasUnverifiedDestination(data)) {
        messages.push(<DestinationVerificationHeadedMessage data={data} isSmall={isSmall} />)
    }



    return messages

}

const MobileGrid = ({ isSmall }) => {

    const { data, isLoading } = useListContext();
    const translate = useTranslate();
    const theme = useTheme();
    const navigate = useNavigate()
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const warningBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(255, 193, 7, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(255, 193, 7, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]
    const inactiveBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]

    if (isLoading || !data || (data?.length <= 0)) return null;

    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >

            <MobileGridHeadedMessage data={data} isSmall={isSmall} />
            {data && data.map((notification, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: isSmall ? "0px 0px 8px 0px" : "0px 8px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px",
                            background: notificationHasUnverifiedDestination(notification) ? warningBg :
                                notificationIsInactive(notification) ? inactiveBg : null,
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <Tooltip title={translate(`resources.notifications.browse_notification`)} disableInteractive >
                                <ButtonBase
                                    onClick={() => { navigate(`/notifications/${notification.notification_id}`) }}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0px',
                                        padding: "0px"
                                    }}
                                >
                                    <NotificationCardHeader notification={notification} permissions={permissions} />
                                    <NotificationCardContent sx={{
                                        width: "100%",
                                    }} notification={notification} />

                                </ButtonBase>

                            </Tooltip>
                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    );
}



export default MobileGrid;


