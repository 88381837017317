// in src/comments.js
import React from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { BooleanField, EditButton, ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, ChipField } from 'react-admin';
import { useTheme, Grid, TableBody, CardContent, Card, Typography, Avatar, Box, Chip, ButtonBase, Tooltip } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { useNavigate } from 'react-router-dom';
import { HookIcon } from '../../hook';





const IntegrationCardHeader = ({ permissions, integration }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Box sx={{
            marginX: "12px",
            marginTop: "12px",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"

        }}>
            <Box
                sx={{
                    marginRight: "24px",

                }}
            >
                <Avatar
                    size={"45px"}
                    style={{ backgroundColor: theme.palette.background.default, width: "45px", height: "45px" }}
                >
                    <HookIcon />
                </Avatar>
            </Box>



            <TableBody sx={{
                "& .MuiTableRow-root": {
                    width: "100%",
                },
                "& .MuiTableCell-root": {

                    width: "100%",
                    margin: "0px",
                    padding: "5px",
                    borderBottomColor: theme.palette.divider,

                }
            }}>
                {integration.name
                    && (
                        <CardDetailRow label={<TextField sx={{ fontSize: "18px", fontWeight: 500 }} source="name" record={integration} />} ValueComponent={() => <div />} />
                    )
                }





            </TableBody>


        </Box>

    );
}


const IntegrationCardContent = ({ integration }) => {
    const translate = useTranslate();


    return (

        <TableBody>
            {integration.device_id && (
                <CardDetailRow label={translate('resources.devices.name', { smart_count: 1 })} ValueComponent={() => <ReferenceField
                    allowEmpty={true}
                    source="device_id"
                    reference="devices"
                    record={integration}
                >
                    <TextField source="name" />
                </ReferenceField>} />
            )}

            {integration.organization_id && (
                <CardDetailRow label={translate('resources.organization.organization')} ValueComponent={() =>
                    <ReferenceField
                        allowEmpty
                        source="organization_id"
                        reference="organizations"
                        sortable={false}
                        record={integration}
                    >
                        <TextField source="name" />
                    </ReferenceField>} />
            )}



            {(integration.active === true || integration.active === false) && (
                <CardDetailRow label={translate('resources.hooks.fields.active')} ValueComponent={() => <BooleanField
                    record={integration}
                    source="active"
                />} />
            )}


        </TableBody>

    );
}

const integrationIsInactive = (integration) => {
    return (!integration ||
        integration.status !== "ACTIVE")


}

const MobileGrid = ({ isSmall }) => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()
    const inactiveBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]


    if (isLoading || (data?.length <= 0)) return null;


    return (

        <Grid
            container
            spacing={isSmall ? 0 : 1}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((integration, index) => (
                <Grid item sm={12} md={3} sx={{
                    width: "100%",
                    margin: isSmall ? "8px 0px 0px 0px" : '0px',
                }} >
                    <Card
                        key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            justifyContent: "space-between",
                            background: integrationIsInactive(integration) ? inactiveBg : null,
                        }}

                    >
                        <Tooltip title={translate(`resources.hooks.browse_integration`)}>
                            <ButtonBase
                                onClick={() => { }}

                                sx={{ display: 'flex', width: "100%" }}
                            >

                                <CardContent sx={{
                                    ...theme.typography.body1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "space-between",
                                    padding: "8px"
                                }}>

                                    <IntegrationCardHeader integration={integration} permissions={permissions} />
                                    <IntegrationCardContent integration={integration} />
                                </CardContent>
                            </ButtonBase>
                        </Tooltip>

                    </Card>
                </Grid>
            ))}
        </Grid>
    )


}




export default MobileGrid