// in src/comments.js
import React from 'react';
import { ReferenceField, TextField, useTranslate, useListContext, usePermissions, FunctionField, DateField, ChipField, useLocaleState } from 'react-admin';
import { Table, useTheme, Grid, TableBody, Typography, CardContent, Card, Avatar, ButtonBase, Box } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { SubscriptionIcon } from '..';
import { useNavigate } from 'react-router-dom';
import CustomChip from '../../../ui/CustomChip';
import { StatusChip } from './StatusChip';




const SubscriptionCardContent = ({ sub }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const [locale, setLocale] = useLocaleState();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>


                    {sub.plan && sub.plan.title && (
                        <CardDetailRow label={translate('resources.subscriptions.fields.plan.title')} ValueComponent={() => <TextField source="plan.title" record={sub} />} />
                    )}
                    {sub.plan && sub.plan.type && (
                        <CardDetailRow label={translate('resources.subscriptions.fields.plan.type')} ValueComponent={() => <TextField source="plan.type" record={sub} />} />
                    )}

                    {sub.status && (
                        <CardDetailRow label={translate('resources.subscriptions.fields.status')} ValueComponent={() => <StatusChip record={sub} />} />
                    )}

                    {sub.start_date && (
                        <CardDetailRow label={translate('resources.subscriptions.fields.start_date')} ValueComponent={() => <DateField source="start_date" record={sub} locales={locale} />} />
                    )}

                    {sub.end_date && (
                        <CardDetailRow label={translate('resources.subscriptions.fields.end_date')} ValueComponent={() => <DateField source="end_date" record={sub} locales={locale} />} />
                    )}



                </TableBody>
            </Table>
        </Box>
    );
}


const SubscriptionCardHeader = ({ permissions, sub }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    <Avatar
                        size={"45px"}
                        style={{ backgroundColor: theme.palette.background.default, width: "45px", height: "45px" }}
                    >
                        <SubscriptionIcon />
                    </Avatar>
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>

                {sub.device_id && (
                    <ReferenceField
                        allowEmpty={true}
                        source="device_id"
                        reference="devices"
                        record={sub}
                    >
                        <FunctionField
                            render={record => <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{record.name ? record.name : record.device_id}</Typography>}
                            sortable={false}
                        />
                    </ReferenceField>)
                }

            </Box>
        </Box>

    );
}

const MobileGrid = () => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((sub, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: "0px 0px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>

                            <ButtonBase
                                onClick={() => { }}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0px',
                                    padding: "0px"
                                }}
                            >
                                <SubscriptionCardHeader sub={sub} permissions={permissions} />
                                <SubscriptionCardContent sx={{
                                    width: "100%",
                                }} sub={sub} />

                            </ButtonBase>


                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    )


}




export default MobileGrid