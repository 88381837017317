import React from 'react'

import {
    Edit,
    required,
    DeleteButton,
    Toolbar,
    SaveButton,
    SimpleForm,
    useTranslate,
} from 'react-admin'
import { Typography, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';



const validateRequired = [required('Champs obligatoire')]

const notificationSubjectChoices = [{ id: 'button_press', name: 'BUTTON_PRESS' },
{ id: 'button_double_pressed', name: 'BUTTON_DOUBLE_PRESSED' },
{ id: 'button_held', name: 'BUTTON_HELD' },
{ id: 'geofence_crossed', name: 'GEOFENCE_CROSSED' },
{ id: 'battery_low', name: 'BATTERY_LOW' },
{ id: 'battery_status_bad', name: 'BATTERY_STATUS_BAD' },
{ id: 'door_opened', name: 'DOOR_OPENED' },
{ id: 'water_leak_detected', name: 'WATER_LEAK_DETECTED' },
{ id: 'shock_detected', name: 'SHOCK_DETECTED' },
{ id: 'rollover_detected', name: 'ROLLOVER_DETECTED' },
{ id: 'temperature_threshold_reached', name: 'TEMPERATURE_THRESHOLD_REACHED' },
{ id: 'co2_threshold_reached', name: 'CO2_THRESHOLD_REACHED' },
{ id: 'humidity_threshold_reached', name: 'HUMIDITY_THRESHOLD_REACHED' },
{ id: 'address_country_changed', name: 'ADDRESS_COUNTRY_CHANGED' },
{ id: 'address_state_changed', name: 'ADDRESS_STATE_CHANGED' },
{ id: 'address_city_changed', name: 'ADDRESS_CITY_CHANGED' },
{ id: 'high_g_thresholds_reached', name: 'HIGH_G_THRESHOLDS_REACHED' },
{ id: 'motion_detected', name: 'MOTION_DETECTED' },
{ id: 'light_status_from_on_to_off_detected', name: 'LIGHT_STATUS_FROM_ON_TO_OFF_DETECTED' },
{ id: 'light_status_from_off_to_on_detected', name: 'LIGHT_STATUS_FROM_OFF_TO_ON_DETECTED' }
]



const ProductEditToolBar = props => (
    <Toolbar {...props} >
        <SaveButton />
        <DeleteButton />
    </Toolbar>
);


const ProductEdit = ({ classes, ...props }) => {
    const translate = useTranslate();
    const theme = useTheme();

    const inputsData = [
        { name: 'image', label: translate('resources.products.fields.image'), validate: validateRequired, width: "480px", type: "file", accept: "image/svg+xml", subLabel: "type: svg , size 200px*200px , max 500KB", maxSize: 500000 },
        { name: 'marketing_name', label: translate('resources.products.fields.marketing_name'), validate: validateRequired, type: "text" },
        { name: 'device_type', label: translate('resources.products.fields.device_type'), validate: validateRequired, type: "select", choices: [{ id: 'unb', name: 'UNB' }, { id: 'ibeacon', name: 'IBEACON' }] },
        { name: 'identifier', label: translate('resources.products.fields.identifier'), validate: validateRequired, type: "text", disabeled: true },
        { name: 'operator', label: translate('resources.products.fields.operator'), validate: validateRequired, type: "text" },
        { name: 'sub_identifier', label: translate('resources.products.fields.sub_identifier'), validate: validateRequired, type: "text", disabeled: true },
        { name: 'description', label: translate('resources.products.fields.description'), validate: validateRequired, type: "text" },
        { name: 'version', label: translate('resources.products.fields.version'), validate: validateRequired, type: "text" },
        { name: 'notification_subjects', label: translate('resources.products.fields.notification_subjects'), width: "480px", height: "250px", type: "selectArrayInput", choices: notificationSubjectChoices },

    ]





    return (
        <Edit
            redirect="list"
            title={'resources.products.create'}
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                },
                marginTop: '20px'
            }}
        >
            <SimpleForm toolbar={<ProductEditToolBar />} >


                {styledFormInputs(inputsData)}


            </SimpleForm>
        </Edit>
    )
}

export default ProductEdit





