import { TablePagination } from "@mui/material";
import { PaginationActions, useListPaginationContext, useTranslate } from "react-admin";
import { useCallback, useMemo } from "react";










const PaginationMenu = ({
}) => {
    const translate = useTranslate();
    const {
        isLoading,
        hasNextPage,
        page,
        perPage,
        total,
        setPage,
    } = useListPaginationContext();


    const totalPages = useMemo(() => {
        return total != null ? Math.ceil(total / perPage) : undefined;
    }, [perPage, total]);


    const handlePageChange = useCallback(
        (event, page) => {
            event && event.stopPropagation();
            if (page < 0 || page > totalPages - 1) {
                throw new Error(
                    translate('ra.navigation.page_out_of_boundaries', {
                        page: page + 1,
                    })
                );
            }
            setPage(page + 1);

        },
        [totalPages, setPage, translate]
    );

    const labelDisplayedRows = useCallback(
        ({ from, to, count }) =>
            count === -1 && hasNextPage
                ? translate('ra.navigation.partial_page_range_info', {
                    offsetBegin: from,
                    offsetEnd: to,
                    _: `%{from}-%{to} of more than %{to}`,
                })
                : translate('ra.navigation.page_range_info', {
                    offsetBegin: from,
                    offsetEnd: to,
                    total: count === -1 ? to : count,
                    _: `%{from}-%{to} of %{count === -1 ? to : count}`,
                }),
        [translate, hasNextPage]
    );

    const labelItem = useCallback(
        type => translate(`ra.navigation.${type}`, { _: `Go to ${type} page` }),
        [translate]
    );


    if (isLoading) {
        return null;
    }


    if (total === 0 || page < 1 || (total != null && page > totalPages)) {
        return null;
    }


    return (
        <TablePagination
            count={total == null ? -1 : total}
            rowsPerPage={perPage}
            rowsPerPageOptions={[]}
            page={page - 1}
            ActionsComponent={(rest) => <PaginationActions siblingCount={0} boundaryCount={1} color="primary"{...rest} />}
            onPageChange={handlePageChange}
            slotProps={{
                actions: {
                    nextButton: { disabled: !hasNextPage }
                }
            }}
            sx={{
                width: "100%",
                margin: "0px 0px 15px 0px",
                display: "flex",
                padding: "0px",
                alignItems: "flex-start",
                flexDirection: "row",
                justifyContent: "center",
                "& .MuiToolbar-root": {
                    display: "flex",
                    boxShadow: "none",
                    flexDirection: "column",
                    margin: "0px",
                    padding: "0px",
                    minHeight: "50px",
                },
                "& .MuiTablePagination-displayedRows": {
                    fontSize: "10px",
                    fontWeight: 500
                },
                "& .MuiTablePagination-actions": {
                    fontSize: "10px"
                }
            }}
            component="div"
            labelRowsPerPage={""}
            labelDisplayedRows={labelDisplayedRows}
            getItemAriaLabel={labelItem}
        />


    )
}



export default PaginationMenu
