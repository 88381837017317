import React from 'react';
import { useTheme, Avatar } from '@mui/material';




const AvatarField = ({ avatarUrl, size }) => {
    const theme = useTheme();
    return (

        <Avatar
            src={`${avatarUrl}?size=${size}x${size}`}
            size={size}
            style={{ width: size, height: size }}
            sx={{ backgroundColor: theme.palette.background.default }}
        />
    )
};

AvatarField.defaultProps = {
    size: 25,
};

export default AvatarField;