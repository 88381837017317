// in src/comments.js
import React from 'react';
import Card from '@mui/material/Card';
import { Avatar, Grid, useTheme, Box, Table, TableBody, CardContent, CardHeader, Typography, Tooltip, ButtonBase } from '@mui/material'
import { ReferenceField, useTranslate, TextField, TopToolbar, useListContext, usePermissions, EditButton, SelectField } from 'react-admin';
import { CardDetailRow } from '../../../ui/Common';
import { UserIcon } from '..';
import { fullName } from './Customer';
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER } from '../../configuration/actions'
import { useNavigate } from 'react-router-dom';




const UserCardContent = ({ user }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>

                    {user.email && (
                        <CardDetailRow label={translate('resources.users.email')} ValueComponent={() => <TextField source="email" record={user} />} />
                    )}
                    {user.phone_number && (
                        <CardDetailRow label={translate('resources.users.phoneNumber')} ValueComponent={() => <TextField source="phone_number" record={user} />} />
                    )}

                    {user.role && (
                        <CardDetailRow label={translate('resources.users.role')} ValueComponent={() =>
                            <SelectField
                                source="role"
                                sortable={false}
                                record={user}
                                choices={[
                                    { id: ROLE_ORGA_ADMIN, name: translate('resources.role.admin') },
                                    { id: ROLE_ORGA_USER, name: translate('resources.role.member') },
                                    { id: ROLE_USER, name: translate('resources.role.owner') },
                                ]}
                            />
                        } />
                    )}

                    {user.organization_id && (
                        <CardDetailRow label={translate('resources.organization.organization')} ValueComponent={() =>
                            <ReferenceField
                                source="organization_id"
                                reference="organizations"
                                sortable={false}
                                record={user}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        } />
                    )}

                </TableBody>
            </Table>
        </Box>
    );
}


const UserCardHeader = ({ permissions, user }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    <Avatar
                        size={"45px"}
                        style={{ backgroundColor: theme.palette.background.default, width: "45px", height: "45px" }}
                    >
                        <UserIcon />
                    </Avatar>
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{user ? `${fullName({ record: user })}` : ""}</Typography>
            </Box>

        </Box>

    );
}

const MobileGrid = ({ isSmall }) => {

    const { data, isLoading } = useListContext();
    const translate = useTranslate();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const navigate = useNavigate()


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((user, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: isSmall ? "0px 0px 8px 0px" : "0px 8px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <Tooltip title={translate(`resources.users.browse_user`)} disableInteractive >
                                <ButtonBase
                                    onClick={() => { navigate(`/users/${user.user_id}`) }}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0px',
                                        padding: "0px"
                                    }}
                                >
                                    <UserCardHeader user={user} permissions={permissions} />
                                    <UserCardContent sx={{
                                        width: "100%",
                                    }} user={user} />

                                </ButtonBase>

                            </Tooltip>
                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    )

}


export default MobileGrid;