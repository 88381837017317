import defaultMessages from 'ra-language-french'

export default {
    ...defaultMessages,
    resources: {
        about: 'Au sujet de',
        month: 'mois',
        year: 'an',
        for: 'pour',
        per: 'par',
        heading_messages: {
            message: "Message",
            verify_notification_destinations: "Vous n'avez pas encore vérifié les sms ou les emails liés à vos alertes, cliquez ici pour les vérifier.",
            enter_code: "Verification",
            verify: "Verifier",
            verified: "Verifié",
            error_verifying: "Erreur de vérification",
            error_resending_code: "Erreur lors de l'envoi d'un nouveau code",
            invalid_code: "Code invalide",
            resend_code: "Renvoyer",
            code_resent: "Code envoyé!",
            code_ask: "Code ?",
            resending_code_limit_reached: "Vous avez atteint le nombre maximum de tentatives de vérification",
            remaing_credit: "Crédit disponible",
            sms: "SMS",
            add_credit: "Recharger",
            device_name: "Nom de la balise:",
            device_id: "Identifiant de la balise:",
        },
        common: {
            name: "Nom",
            id: "ID",
            errorOccured: 'Une erreur s\'est produite',
            account: 'Compte',
            search: 'Rechercher',
            a_search: 'Recherche',
            tools: 'Outils',
            actions: "Actions",
            optional: "Optionnel",
            cancel: 'Annuler',
            clear: 'Effacer',
            ok: 'Ok',
            history: 'Historique',
            help: 'Aide',
            documentation: 'Documentation',
            contact: "Contact",
            developers: 'Développeurs',
            delete_history: 'Suppression historique balise',
            delete_history_paragraph: 'Etes-vous sûr de vouloir supprimer l\'historique de la balise ? Toutes les positions, mesures, etc seront supprimées définitivement. Cette action est permanente et irréversible.',
            update_success: "Mise à jour réussie",
            next: 'Suivant',
            back: 'Précédent',
            home_redirect: 'Vous allez être redirigé vers la page d\'acceuil...',
            filters: "Filtres",
            filter: "Filtrer",
            dark_mode: "Mode sombre",
            light_mode: "Mode clair",
            language: "Langue",
            i_confirm: "Je confirme",
            loading_dialog_title: "Chargement...",
            loading_dialog_content: "Veuillez patienter pendant que nous synchronisons vos données",
            connecting_dialog_title: "Connexion en cours...",
            apply_filters: "Appliquer",
            reset_filters: "Réinitialiser",
            view_more: "voir plus",
            do_not_show_this_message_again: "Ne plus afficher ce message",
            favorite_filters: "Recherches favorites"

        },
        errors: {
            "file_is_larger_than_500_KB": "Erreur : Le fichier a une taille supérieure à 500 Kilo bytes",
            "file_is_larger_than_200_KB": "Erreur : Le fichier a une taille supérieure à 200 Kilo bytes",
            "file_is_larger_than_2_MB": "Erreur : Le fichier a une taille supérieure à 2 Mega bytes",
            "file_is_larger_than_5_MB": "Erreur : Le fichier a une taille supérieure à 5 Mega bytes",
            "file_is_larger_than_1_MB": "Erreur : Le fichier a une taille supérieure à 1 Mega bytes",
        },
        sidebar: {
            device_sub_plans: "Plans des balises",
            sms_credit_plans: "Plans des crédits sms",
            device_sub: "Abonnements des balises",
            sections: {
                dashboard: "Tableau de bord",
                devices: "Balises",
                data: "Données",
                notifications: "Notifications",
                members: "Membres",
                developers: "Développeurs",
                assistance: "Assistance",
                account: "Compte",
                subscriptions: "Abonnements",
                website_info: "Informations",
                settings: "Paramètres"
            }
        },
        configuration: {
            name: 'Configuration',
            general: 'Général',
        },
        data_mode: {
            card_mode: "MODE CARTES",
            table_mode: "MODE TABLE"
        },
        dashboard: {
            name: 'Tableau de bord',
            active: "Actifs",
            unpaid: "Non payés",
            titles: {
                device_subscriptions_summary: "Mes abonnements",
                number_of_devices_in_account: "Mes balises",
                number_of_devices_in_account_by_products: "Mes balises par produit",
                sms_credit_summary: "Mon crédit sms",
                locations_summary: "Positions de mes balises",
                number_of_nack_alerts: "Alertes non acquitées",
                number_of_alerts: "Mes alertes créées",
                number_of_stationary_devices: "Mes balises immobiles",
                number_of_devices_outside_organization_boundary: "Mes balises hors périmètre"
            },
            number_of_devices_outside_organization_boundary: {
                organization: "Organisation",
                organization_address: "Adresse du site",
                boundary_radius: "Rayon du périmètre",
                number_of_devices: "Nombre de balises",
                created_at: "Données mises à jour le:",
                search_text: "Mes balises hors périmètre de l'organisation %{organizationName}"
            },
            number_of_stationary_devices: {
                for_more_than_10_days: "Depuis plus de 10 jours",
                for_more_than_20_days: "Depuis plus de 20 jours",
                for_more_than_30_days: "Depuis plus de 30 jours",
                for_more_than_50_days: "Depuis plus de 50 jours",
                for_more_than_70_days: "Depuis plus de 70 jours",
                created_at: "Données mises à jour le:",
                notice: "Une balise est considérée comme immobile tant qu'elle ne s'est pas déplacée de plus de 100 mètres.",
                devices_list_subtitles: {
                    for_more_than_10_days: "Mes balises immobiles depuis plus de 10 jours",
                    for_more_than_20_days: "Mes balises immobiles depuis plus de 20 jours",
                    for_more_than_30_days: "Mes balises immobiles depuis plus de 30 jours",
                    for_more_than_50_days: "Mes balises immobiles depuis plus de 50 jours",
                    for_more_than_70_days: "Mes balises immobiles depuis plus de 70 jours",
                }

            },
            number_of_devices_in_account: {
                total_devices: "Total",
                total_active_devices: "Total active",
                total_inactive_devices: "Total inactive",
                created_at: "Données mises à jour le:"
            },
            number_of_alerts: {
                nbr_of_alerts: "Total créées",
                nbr_of_email_alerts: "Total des alertes email",
                nbr_of_sms_alerts: "Total des alertes sms",
            },
            sms_credit_summary: {
                nbr_of_remaining_credit: "Crédit disponible"
            }

        },
        addresses: {
            name: "Adresses sauvegardées",
            radius_meters: "Rayon en mètres",
            latitude: "Latitude",
            longitude: "Longitude",
        },
        ui_setup: {
            name: "Préférences d'affichage",
            dashboard: "Tableau de bord",

        },
        devices: {
            page_titles: {
                my_devices: "Mes balises",
                add_device: "Ajouter une balise",
                add_devices_bulk: "Ajouter des balises",
                edit_device: "Modifier la balise"
            },
            add_bulk_device: 'Ajouter des balises',
            add_new_device: 'Ajouter une balise',
            add_new_device_succes: 'Balise Ajoutée',
            device_image_deleted_success: 'Image de la balise supprimée',
            device_image_delete_confirmation: "Êtes-vous sûr de vouloir supprimer l'image de votre balise?",
            device_sub_plans: "Plans d'abonnement des balises",
            technology: 'Technologie',
            activate_new_device: 'Activer une balise',
            activate: 'Activation',
            advsearch: 'Recherche avancée',
            choose_advsearch_option: "Veuillez choisir l'option de recherche avancée ci- dessous",
            go_tracking_mode: "Passer en mode LIVE",
            go_tracking_mode_because_pending_more_than_24_jours: "La demande de mode LIVE n'a pas été prise en compte par la balise dans les dernières 24 heures. Vous pouvez maintenant faire une nouvelle tentative.",
            tracking_mode_info_title: "Comment ça marche?",
            tracking_mode_info_1: "En activant le mode LIVE la balise va basculer à 1 position toutes les minutes en cas de mouvement, ou 1 position toute les 10 minutes sinon, et ceci pendant 30 minutes.",
            tracking_mode_info_2: "A la fin des 30 minutes la balise reviendra dans le mode correspondant à son PLAN (Basique / Régulier / Tracking)",
            tracking_mode_info_3: "Le mode LIVE est gratuit et ne donnera pas lieu à un changement de l'abonnement",
            tracking_mode_info_4: "Attention, le délai de mise en œuvre du mode LIVE dépendra du Plan qui a été activé pour la balise:",
            tracking_mode_info_5: "- 24h pour le plan Basique",
            tracking_mode_info_6: "- 1h pour le plan Régulier",
            tracking_mode_info_7: "- 10min pour le plan Tracking",
            last_tracking_mode_entry: "Dernière entrée en mode LIVE",
            success_setting_on_tracking_mode: "Requête de mise en mode LIVE envoyée avec succès",
            error_setting_on_tracking_mode: "Une erreur s'est produite lors de l'envoi de la requête de mise en mode LIVE.",
            recovery_mode_status: "Statut",
            last_recovery_mode_request: "Date de la dernière demande",
            last_recovery_mode_entry: "Date de la dernière entrée en mode LIVE",
            last_recovery_mode_exit: "Date de la dernière sortie du mode LIVE",
            already_attached: 'Equipement déja attaché',
            attaching: "La balise est en cours d'activation...",
            attached: 'La balise a été activée',
            attach: "Activer la balise",
            error_attachement_checkout: "Une erreur s'est produite lors de l'attachement de votre balise !  Vous allez être redirigé vers la page de contact du support... ",
            available: 'Equipement disponible',
            battery_status: {
                bad: 'Mauvais',
                good: 'Bon',
            },
            product: 'Produit',
            type: "Type",
            configuration: 'Configurer la balise',
            create: 'Ajouter une nouvelle balise',
            delete: 'Détacher',
            device_id: 'Identifiant balise',
            device_name: 'Nom balise',
            enter_device_id: "Entrez l'identifiant de votre balise",
            enter_device_id_instruction: `Merci de renseigner l'identifiant inscrit au dos de votre balise.`,
            enter_voucher: 'Saisir le code inscrit sur votre carte prépayée',
            error_attachement: 'Erreur attachement device: ',
            fastsearch: 'Recherche rapide',
            edit_title: 'Configuration %{deviceName}',
            fields: {
                name: 'Nom',
                mac_address: '#ID',
                serial_number: '#ID (serial_number)',
                battery: 'Batterie',
                software_version: 'Firmware',
                secondary_mac_address: 'Adresse mac secondaire',
                minor: 'Mineur',
                major: 'Majeur',
                proximity_uuid: 'Proximity UUID',
                ibeacon_ids_file: 'Ficher des identifiants (colonnes: mac_address,proximity_uuid,minor,major,secondary_mac_address)',
                unb_ids_file: 'Ficher des identifiants (colonnes: serial_number,secondary_mac_address)',
                last_subscription: {
                    status: 'Statut',
                    start_date: 'Date de début',
                    end_date: 'Date de fin',
                },
                image: 'Image',
                last_seen: 'Dernière transmission',
                owner: 'Propriétaire',
                organization: 'Organisation',
                actions: 'Actions',
                tags: "Tags"
            },
            edit_sections: {
                general: "Général",
                configuration: "Configuration"
            },
            config: {
                geolocation_period: 'Fréquence de géolocalisation (minutes)',
                retry_period: 'Retransmission de la position (minutes)',
                wifi_sniffing: 'Scanner les réseaux WIFI',
                bluetooth_advertising: 'Emission bluetooth',
                bluetooth_advertising_frequency: 'Fréquence d\'émission bluetooth (secondes)',
                temperature_low_limit: 'Seuil température basse',
                temperature_up_limit: 'Seuil température haute',
                humidity_low_limit: 'Seuil humidité basse',
                humidity_up_limit: 'Seuil humidité haute',
                send_message_in_motion: 'Envoi des messages en mouvement',
                co2: {
                    frequencies: "Fréquences :",
                    plateform: "Plateforme :",
                    plateformFrequency: "fréquence plateforme (min)",
                    plateformCaption: "Fréquence de remonter de mesure sur la plateforme (Défaut = 30 min)",
                    sensor: "Capteur :",
                    extra_measurement_count: "Nombre de mesures supplémentaires",
                    sensorCaption: "Nombre de mesures dans le capteur entre deux remontées (Défaut = 2)",
                    threshold: "Seuil d'alerte :",
                    thresholdCaption: "(Défaut = 800 ppm)",
                    offset: "Étalonnage",
                    offsetCaption: "Il correspond au taux de concentration minimum enregistré (Défaut = 400 ppm)"

                },
                plans: {
                    downgrading_sub_alert: "VOUS CHANGEZ VOTRE PLAN D'ABONNEMENT",
                    upgrading_sub_alert: "VOUS CHANGEZ VOTRE PLAN D'ABONNEMENT",
                    switching_sub_alert: "En choisissant cette configuration, votre abonnement mensuel passera de",
                    switching_sub_alert_to: "En choisissant cette configuration, votre abonnement mensuel passera à",
                    notice: "Notice",
                    to: "à",
                    return: "Retourner",
                    continue: "Continuer",
                    select_plan: "Sélectionnez un plan d'abonnement",
                    select_plan_as_admin: "Sélectionnez une configuration en tant que Super Admin",
                    current_conf: "Configuration en cours",
                    current_subs: "Abonnement en cours",
                    diff_conf_subs: "ALERTE : La configuration de la balise n'est pas à jour avec l'abonnement",
                    once_every: "Une position tous les",
                    geolocation_period: "période de géolocalisation en mouvement",
                    keep_alive: "période de géolocalisation en arrêt",
                    subscription_cost: "coût de l'abonnement",
                    price: "Prix",
                    minutes: "minutes",
                    hours: "heures",
                    per_mounth: " / mois",
                    since: "depuis le"
                },
                egiot: {
                    init_battery: "Remplacement des piles : "
                },
                tilocker: {
                    unlock_requested: "Déverrouiller ",
                    unlock_request_date_time: "Dernière demande de déverrouillage",
                    geolocation_upload_period: "Période de géolocalisation (en secondes)",
                    geolocation_upload_period_request_date_time: "Dernière mise à jour de la période de géolocalisation",
                    biked_number: "Code de déverrouillage",
                    bike_number_max: "Le code doit être composé de 16 digits en hexadecimal",
                    bike_number_min: "Le code doit être composé de 16 digits en hexadecimal",
                    bike_search_requested: " Sonnerie ",
                    bike_search_request_date_time: "Dernière demande de sonnerie",
                    history: "Historique"

                }
            },
            device: 'Balise',
            general: 'Général',
            group: 'Groupe',
            group_name: 'Nom du groupe',
            delete_group: 'Supprimer le groupe',
            subscription: 'Abonnement',
            owner: 'Propriétaire',
            from: 'du',
            geofence: 'Barrière virtuelle',
            browse_location: 'Consulter la position',
            browse_subscription: 'Consulter l\'abonnement',
            geolocation: 'Devices Location',
            identifier: 'Identifiant',
            labels: {
                acknowledgeAlert: "Acquitter l'alerte",
                acknowledgeMotion: 'Acquitter le mouvement',
                acknowledgeRollover: 'Acquitter le renversement',
                checkBattery: 'Vérifier la batterie',
                configuration: 'Configuration',
                subscription: 'Abonnement',
                coordinates: 'Coordonnées',
                last_transmission: 'Dernière transmission',
                latitude: 'Latitude',
                location: 'Position: ',
                location_nbr: 'Position ',
                location_geo: 'Adresse',
                locationsHistory: 'Historique des positions',
                longitude: 'Longitude',
                accuracy: "Précision",
                measures: "Mesures",
                speed: "Vitesse",
                changeIcon: 'Changer l\'icône',
                tracking_mode: "Mode LIVE"
            },
            location: 'position |||| positions',
            name: 'Balise |||| Balises',
            no_device_subscription: `La balise ne possède pas d'abonnement`,
            not_found: 'Identifiant balise inconnu',
            notifications: 'Evènements',
            properties: 'Propriétés',
            review_info: `Revue de vos informations`,
            saisir_code: 'Saisir le code inscrit sur votre carte prépayée',
            select_offer: 'Sélectionnez une offre ci-dessous',
            select_offer_type: 'Sélectionnez le type d\'offre ci-dessous',
            select_device_information: 'Les informations de la balise',
            status: {
                unknow: 'Inconnu',
            },
            thks_number: `Merci de renseigner l'identifiant inscrit au dos de votre balise.`,
            tifiz_number: 'Identifiant balise',
            to: 'au',
            your_device: 'Votre balise',
            no_location: 'Aucune position',
            no_subscription: 'Aucun abonnement',
            timestamp: 'Date/Heure',
            rollover: {
                state: 'Etat',
                name: 'Renversement',
                detected: 'Renversé',
                clear: 'Pas renversé'
            },
            activity: {
                still: 'Immobile',
                moving: 'En mouvement'
            },
            browse_measure: "Consulter l'historique des mesures",
            browse_notification_log: "Consulter l'historique des alertes",
        },
        products: {
            browse_product: "Consulter le produit",
            page_titles: {
                products: "Produits",
                create_product: "Créer un produit",
                edit_product: "Editer un produit"
            },
            create: "Création d'un produit",
            name: 'Produit |||| Produits',
            edit: 'Editer un produit',
            fields: {
                marketing_name: "Nom commercial",
                device_type: "Type de balise",
                identifier: "Identifiant",
                sub_identifier: "Sous identifiant",
                description: "Description",
                version: "Version",
                operator: "Operateur",
                image: "Image",
                notification_subjects: "Evénements d'alerte",
            }
        },
        sms_credit_plans: {
            sections: {
                general: 'Générale',
                pricing: 'La tarification',
                app_owner_fees: 'Frais de la plateforme',
                stripe: "Stripe"

            },
            fields: {
                viewership_rule: "Visibilité",
                root_organization: "Root organisation",
                created_by: "Créée par",
            },
            viewership_rule: {
                public: "Publique",
                private: "Privé",
                archived: "Archivé"

            },
            add_new_device_sub_plans: "Ajouter un nouveau plan",
            create_success: "Le plan a été créé avec succès, veuillez demander à votre administrateur d'application de valider le plan.",
            edit_success: "Le plan a été modifié avec succès, veuillez demander à votre administrateur d'application de valider le plan.",
            name: "Plan de recharge de crédit SMS",
            create: "Créer un plan de recharge de crédit SMS",
            update: "Modifier le plan de recharge de crédit SMS",
            delete: "Supprimer le plan de recharge de crédit SMS",
            list: "Liste des plans de recharge de crédit SMS",
            activate: "Activer le plan de recharge de crédit SMS",
            archive: "Archiver le plan de recharge de crédit SMS",
            title_fr: "Titre en français",
            description_fr: "Description en français",
            title_en: "Titre en anglais",
            description_en: "Description en anglais",
            title_es: "Titre en espagnol",
            description_es: "Description en espagnol",
            root_organization_id: "Organisation racine",
            is_default: "Définir par défaut",
            amount: "Montant HT",
            sms_quantity: "Nombre de SMS",
            tax_percent: "Taux de taxe (%)",
            currency: "Devise",
            app_owner_percent_fees: "Frais en pourcentage de la plateforme (%)",
            app_owner_fixed_fees: "Frais fixe de la plateforme (HT)",
            payment_method_type: "Type de paiement",
            stripe_price_id: "ID du prix Stripe (stripe_price_id)",
            stripe_tax_id: "ID de la taxe Stripe (stripe_tax_id)",



        },
        device_sub_plans: {
            device_sub_plans: "Plans d'abonnement de balise",
            sections: {
                general: 'Générale',
                pricing: 'La tarification',
                app_owner_fees: 'Frais de la plateforme',
                stripe: "Stripe"

            },
            fields: {
                viewership_rule: "Visibilité",
                root_organization: "Root organisation",
                created_by: "Créée par",
            },
            viewership_rule: {
                public: "Publique",
                private: "Privé",
                archived: "Archivé"

            },
            add_new_device_sub_plans: "Ajouter un nouveau plan",
            create_success: "Le plan a été créé avec succès, veuillez demander à votre administrateur d'application de valider le plan.",
            edit_success: "Le plan a été modifié avec succès, veuillez demander à votre administrateur d'application de valider le plan.",
            name: "Plan d'abonnement de balise",
            create: "Créer un plan d'abonnement de balise",
            update: "Modifier le plan d'abonnement de balise",
            delete: "Supprimer le plan d'abonnement de balise",
            list: "Liste des plans d'abonnement de balise",
            activate: "Activer le plan d'abonnement de balise",
            archive: "Archiver le plan d'abonnement de balise",
            title_fr: "Titre en français",
            description_fr: "Description en français",
            title_en: "Titre en anglais",
            description_en: "Description en anglais",
            title_es: "Titre en espagnol",
            description_es: "Description en espagnol",
            root_organization_id: "Organisation racine",
            is_default: "Définir par défaut",
            amount: "Montant HT",
            tax_percent: "Taux de taxe (%)",
            currency: "Devise",
            type: "Type",
            frequency: "Fréquence",
            frequency_interval: "Intervalle de fréquence",
            trial_period_days: "Nombre de jours d'essai",
            app_owner_percent_fees: "Frais en pourcentage de la plateforme (%)",
            app_owner_fixed_fees: "Frais fixe de la plateforme (HT)",
            payment_method_type: "Type de paiement",
            stripe_price_id: "ID du prix Stripe (stripe_price_id)",
            stripe_tax_id: "ID de la taxe Stripe (stripe_tax_id)",



        },
        export: {
            create: 'Export des données',
            action: 'Lancer l\'export',
            fields: {
                device_ids: 'Balises',
                event_type: 'Evénement',
                start_date: ' Date de début',
                end_date: 'Date de fin',
                format: 'Format des données'
            },
            export_starting: 'Une archive est en cours de génération. Vous allez recevoir un lien de téléchargement par email.'
        },
        exportPdf: {
            download: 'Télécharger',
            downloading: 'Téléchargement en cours...',
            creationInProgress: 'La création de votre rapport PDF en cours',
            errors: {
                errorCreation: "Une erreur s'est produite lors de la création de votre rapport PDF",
                noLocations: 'Aucune position à télécharger !',
                noMeasures: 'Aucune mesure à télécharger !'
            },
            locationsHistoryDocument: {
                title: 'Rapport des positions',
                created_on: "Créé le",
                date: 'Date : ',
                beacon: 'Balise',
                search: 'Recherche',
                results: "Résultats",
                request: 'Requête',
                id: "#ID",
                name: "Nom",
                start_date: 'Date de début',
                results_start_date: "Date de la première position",
                end_date: 'Date de fin',
                results_end_date: "Date de la dernière position",
                number_of_results: "Nombre",
                part: "Partie",
                itinerary_map: "Carte de l'itinéraire",
                table: {
                    title: "Table de l'historique",
                    location: 'Position',
                    dateTime: 'Date et heure',
                    adress: 'Adresse',
                    latitude: 'Latitude',
                    longitude: 'Longitude',
                    longitude: 'Longitude',
                    accuracy: "Précision",
                },
                note: "Remarque",
                warning_limited_results_size: "Les rapports générés à partir de l'interface utilisateur sont limités à 1000 positions. Par conséquent, les résultats ci-dessous représentent les 1000 positions de la partie %{part_number}."
            },
            measuresHistoryDocument: {
                title: 'RAPPORT DES MESURES',
                date: 'Date : ',
                beacon: 'Balise',
                period: 'Période',
                id: "#ID",
                name: "Nom",
                from: 'De',
                to: 'À',
                table: {
                    title: "Table de l'historique",
                    measure: 'Mesure',
                    dateTime: 'Date et heure',
                    type: 'Type',
                    value: 'Valeur',
                }
            }

        },
        fops: {
            name: 'Moyen de paiement |||| Moyens de paiement',
            error_billing_portal: "Une erreur s'est produite lors de la création de la session du portail de moyen de paiement. Veuillez réessayer plus tard."
        },
        geofences: {
            name: 'Barrière virtuelle |||| Barrières virtuelles ',
            delete: 'Supprimer la barrière virtuelle',
            create: "Création d'une barrière virtuelle",
            edit: "Edition d'une barrière virtuelle",
            center: 'Centre',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alerte',
            fields: {
                radius: 'Rayon',
                address: 'Adresse',
                on_entry: 'sur entrée',
                on_exit: 'sur sortie',
                active: 'Actif',
            },
            draw: {
                save_modifications: 'Enregistrer les modifications',
                cancel_modifications: 'Annuler les modifications',
                select_geofence: 'Sélectionnez les carrés pour modifier le geofence',
                click_cancel: 'Cliquez sur annuler pour rétablir les modifications',
            },
            device_search: 'Recherchez une balise',
            enter_address: 'Entrez une adresse',
            no_device_selected: 'Vous devez sélectionnez une balise',
            no_center_defined: "Vous n'avez pas défini le centre du geofence",
        },
        humidity: {
            head: 'Humidité',
            value: 'Valeur',
            date: 'Dernier relevé',
        },
        battery: {
            head: 'Niveau de la batterie / piles',
        },
        pressure: {
            head: 'Pression',
        },
        luminosity: {
            head: 'Luminosité',
        },
        light_status: {
            head: 'État de la lumière',
        },
        formaldehyde: {
            head: 'Formaldéhyde',
        },
        tvoc: {
            head: 'Tvoc (Composés organiques volatils)',

        },
        noise: {
            head: 'Bruit',
        },
        ammonia: {
            head: 'Amoniac',
        },
        mox: {
            head: 'Mox',
        },
        aqi: {
            head: 'Indice de qualité d\’air',
            help_legend: {
                head_ref_levels: "Niveaux de référence",
                head_air_info: "Informations sur l'air",
                head_tvoc_mg_m3: "TVOC (mg/m3)",
                head_air_quality: "Qualité de l'air",
                level1: "Niveau 1",
                level2: "Niveau 2",
                level3: "Niveau 3",
                level4: "Niveau 4",
                level5: "Niveau 5",
                air_info_level1: "Air pur et hygiénique (valeur cible)",
                air_info_level2: "Bonne qualité de l'air (si aucun seuil n'est dépassé)",
                air_info_level3: "Préoccupations notables en matière de confort (non recommandé pour une exposition > 12 mois)",
                air_info_level4: "Problèmes de confort importants (exposition déconseillée > 1 mois)",
                air_info_level5: "Conditions inacceptables (déconseillé)",
                air_quality_level1: "Très bonne",
                air_quality_level2: "Bonne",
                air_quality_level3: "Moyenne",
                air_quality_level4: "médiocre",
                air_quality_level5: "Mauvaise",

            }

        },
        pm1: {
            head: 'Particules PM1',
        },
        pm2p5: {
            head: 'Particules PM2.5',
        },
        pm10: {
            head: 'Particules PM10',
        },
        benzen: {
            head: 'Benzen',
        },
        co2: {
            head: 'CO2',
            head_min: 'Co2',
            treshold: 'Seuil',
            max: 'Maximum',
            min: 'Minimum',
            avg: 'Moyenne',

        },
        internal_error: 'Erreur interne',
        lang: 'Langage',
        door: {
            head: 'Ouverture',
            last: 'Value',
            date: 'Last check',
            open: 'Ouvert',
            closed: 'Fermé',
        },
        water_leak: {
            head: 'Fuite eau',
            on: 'Active',
            off: 'Inactive',
        },
        presence: {
            head: 'Presence',
        },
        battery_status: {
            head: 'Status de la batterie',
        },
        button: {
            head: 'Bouton',
        },
        motion: {
            head: 'Mouvement',
        },
        location: {
            head: 'Position'
        },
        locations: {
            name: 'Position |||| Positions',
            labels: {
                addDevice: 'Ajouter une nouvelle balise',
                devicesPositions: 'Positions des balises',
                hideMenu: 'Menu',
                exportData: 'Exporter les positions',
                addLocation: 'Ajouter une position',
                delete: "Supprimer l'historique",
                location: 'Position',
                search: 'Rechercher une balise',
                viewHistory: "Afficher l'historique"
            },
            fields: {
                name: "Nom",
                device_id: "Balise",
                timestamp: "Date",
                latitude: "Latitude",
                longitude: "Longitude",
                altitude: "Altitude",
                speed: "Vitesse",
                accuracy: "Précision (mètres)",
                formatted_address: "Adresse complète",
                country: "Pays",
                state: "État (state)",
                city: "Ville",
                postcode: "Code postal",
            },
            locationNone: 'Non',
            location5: '5 dernières positions',
            location25: '25 dernières positions',
            location50: '50 dernières positions',
            location100: '100 dernières positions',
            locationYesterday: 'Hier',
            locationToday: `Aujourd'hui`,
            customized: "Personnalisée",
            searchOption: "Option de recherche",
            accuracy: {
                all: "Toutes",
                lessThan20km: "Moins de 20km",
                lessThan10km: "Moins de 10km",
                lessThan5km: "Moins de 5km",
                lessThan1km: "Moins de 1km",
                lessThan500m: "Moins de 500m",
                lessThan100m: "Moins de 100m",
                lessThan50m: "Moins de 50m",
            }

        },
        map: {
            road: 'Route',
            satellite: 'Satellite',
            routing: 'Itinéraire',
            routing_mode: {
                driving: 'Voiture',
                cycling: 'Vélo',
                walking: 'Marche',
                boat: 'Bateau',
                no_routing: 'Aucun itinéraire',
            },
            clustring: "Clustring"
        },
        markerpopup: 'Afficher sur click',
        notification_logs: {
            name: "Journal des alertes",
            nacknowledged_notifications: "Les alertes non acquittées",
            timestamp: 'Date',
            description: 'Description',
            acknowledge: "Acquitter",
            nacknowledge: "Annuler l'aquittement",
            acknowledged: "Acquittée",
            nacknowledged: "Non acquittée",
            request_success_acknowledged: "Alerte acquittée avec succès",
            request_success_nacknowledged: "Alerte non acquittée",
            request_error_acknowledged: "Erreur de requête",
            request_error_nacknowledged: "Erreur de requête",
            notifLog5: "5 dernières alertes",
            notifLog25: "25 dernières alertes",
            notifLog50: "50 dernières alertes",
            notifLog100: "100 dernières alertes",
            notifLogYesterday: 'Hier',
            notifLogToday: `Aujourd'hui`,
            customized: "Personnalisée",
        },
        notification_log: {
            head: "Journal des alertes",
        },
        notifications: {
            info_about_destination_verification: {
                title: "Vous devrez vérifier les e-mails ou les numéros de téléphone que vous indiquez comme destination de votre alerte",
                message1: "Vous recevrez un code sur votre e-mail ou par sms, que vous devrez saisir sur la page de notification en cliquant sur “vérification” en haut de la page",
                message2: "Ceci pour des raisons de sécurité et pour s'assurer que l'alerte fonctionne correctement."
            },
            browse_notification: "Consulter l\'alerte",
            button_press: 'Appui bouton',
            button_double_pressed: 'Appui bouton - Double clic',
            button_held: 'Appui bouton - Appui long',
            button_pressed: 'Appui bouton - Simple clic',
            shock_detected: 'Choc détecté',
            motion_detected: 'Mouvement détécté',
            light_status_from_on_to_off_detected: "L\'état de la lumière passe de allumé (ON) à éteint (OFF)",
            light_status_from_off_to_on_detected: "L\'état de la lumière passe de éteint (OFF) à allumé (ON)",
            rollover_detected: 'Renversement détecté',
            door_opened: 'Porte ouverte',
            water_leak_detected: 'Détection d\'eau',
            temperature_threshold_reached: 'Seuil de température dépassé',
            co2_threshold_reached: 'Seuil de concentration du Co2 dépassé',
            humidity_threshold_reached: 'Seuil d\'humidité dépassé',
            battery_status_bad: 'Statut batterie mauvais',
            battery_low: 'Niveau de batterie faible',
            address_country_changed: 'Changement de pays',
            address_state_changed: 'Changement de région',
            address_city_changed: 'Changement de ville',
            high_g_thresholds_reached: "Seuils de la force G dépassés",
            channel: 'Canal',
            create: "Création d'une alerte",
            edit: "Edition d'une alerte",
            delete: "Supprimer l'alerte",
            enter_phone_number: 'Entrez un numéro de téléphone',
            email: 'Email',
            subject: 'Evènement',
            fields: {
                language: "Langue",
                english: "Anglais",
                french: "Français",
                spanish: "Espagnol",
                timezone: "Fuseau horaire",
                device_id: 'Balise',
                channel: 'Canal',
                created_by: "Créée par",
                subject: 'Evènement',
                destinations: 'Destinataires',
                name: "Nom de l'alerte",
                applyOn: "Appliquer sur",
                applyOnOneDevice: "Une seule balise",
                applyOnGroupOfDevices: "Un groupe de balises",
                applyOnGroupOfOrganizations: "Un groupe d'organisations",
                device_ids: "Balises",
                organization_ids: "Organisations",
                active: "Active",
                geofence_input_draw_on_map: "Cliquez ci-dessous si vous souhaitez dessiner la barrière virtuelle sur une carte",
                geofence_input_method: "Méthode de saisie de la barrière virtuelle",
                geofence_input_method_with_map: "Dessiner sur une carte",
                geofence_input_method_without_map: "Saisir les coordonnées manuellement",
                geofence_latitude: "Latitude de la barrière virtuelle",
                geofence_longitude: "Longitude de la barrière virtuelle",
                geofence_radius: "Rayon de la barrière virtuelle",
                geofence_formatted_address: "Adresse de la barrière virtuelle",
                geofence_on_entry: "Actif à l'entrée",
                geofence_on_exit: "Actif à la sortie",
                high_g_peak_threshold: "Seuil de du pic (G)",
                high_g_average_threshold: "Seuil de la moyenne (G)",
                high_g_duration_threshold: "Seuil de la durée (ms)",
                battery_threshold_value: "Valeur du seuil",
                battery_threshold_unit: "Unité de seuil",
                co2_threshold_value: "Valeur seuil",
                co2_threshold_unit: "Unité de seuil",
                humidity_threshold_value: "Valeur seuil",
                humidity_threshold_unit: "Unité de seuil",
                temperature_threshold_value: "Valeur seuil",
                temperature_threshold_unit: "Unité de seuil",
                calendar: {
                    calendar: "Calendrier",
                    monday: "Lundi",
                    tuesday: "Mardi",
                    wednesday: "Mercredi",
                    thursday: "Jeudi",
                    friday: "Vendredi",
                    saturday: "Samedi",
                    sunday: "Dimanche",
                    weekdays: "Jour de la semaine",
                    weekend: "Fin de semaine",
                    alldays: "Tous les jours",
                    clear: "Effacer"
                }
            },
            geofence_crossed: 'Franchissement de barrière virtuelle',
            message: 'message',
            name: 'Alerte |||| Alertes',
            option: 'Option',
            update: 'Editer',
            notifications_redirect: 'Vous allez être redirigé vers la page des alertes...',
            sms_credit: "Crédit Sms",
            sms_credit_success: 'Votre recharge a bien été pris en compte',
            cannot_edit_notification_on_mobile_title: "Modification d'alerte non disponible sur mobile",
            cannot_edit_notification_on_mobile_message: "Cette alerte utilise un calendrier avec plusieurs intervalles temporels sur une même journée. Veuillez effectuer cette modification depuis un ordinateur ou un appareil avec un écran large."
        },
        integrations: {
            empty_jd_integrations: "L'intégration de votre organisation avec la plateforme John Deere n'a pas encore été créée",
            create_new_jd_integration: "Créer une intégration avec John Deere",
            click_below_to_create_new_jd_integration: "Cliquez ci-dessous pour commencer l'intégration avec votre compte john deere operation center",
            empty_tibib_integrations: "L'intégration de votre organisation avec la plateforme Tibib n'a pas encore été créée",
            create_new_tibib_integration: "Créer une intégration avec Tibib",
            click_below_to_create_new_tibib_integration: "Cliquez ci-dessous pour commencer l'intégration avec la plateforme Tibib",
            browse_jd_integrations: "Aller aux intégrations John Deere",
            browse_tibib_integrations: "Aller aux intégrations Tibib Live",
            list_sections: {
                tibib: "Tibib Live",
                johndeere: "John Deere Operation Center"
            },
            fields: {
                organization: "Organisation",
                jdOrganization: "ID de votre organisation sur John Deere Operation Center",
                email: 'Email',
                name: "Nom",
                description: "Description",
            },
            name: 'Intégration |||| Intégrations',
            info_about_integration_john_deere: {
                title1: "De quoi s'agit-il ?",
                message1_1: "Cette fonction permet de relier les balises d'une organisation à votre John Deere Operation Center",
                message1_2: "Cela vous permet de visualiser les positions de vos appareils directement sur la carte de l'Operation Center, à côté de vos équipements",
                title2: "Comment ça marche ?",
                message2: " sélectionner l'organisation que vous souhaitez lIl vous suffit de sélectionner l'organisation que vous souhaitez lIl vous suffit de sélectionner l'organisation que vous souhaitez lation que vous souhaitez lIl vous suffit de sélectionner l'organisation que vous souhaitez lIl vous suffit de sélectionner l'organisation que vous souhaitez lIl vous suffit de sélectionner l'organisation que vous souhaitez lIl vous suffit de sélectionner l'organisation que vous souhaitez l lier à Tibib Live, puis de cliquer sur le bouton Enregistrer",
            },
            info_about_integration_tibib: {
                title: "De quoi s'agit-il ?",
                message1: "Cette fonctionnalité relie les balises d'une organisation à la plateforme Tibib Live ",
                title2: "Comment ça marche ?",
                message2: "Il vous suffit de sélectionner l'organisation que vous souhaitez lier à Tibib Live, puis de cliquer sur le bouton “Enregistrer",
            }

        },
        organization:
        {
            description: 'Description',
            name: 'Nom',
            organization: 'Organisation',
            parent: 'Organisation parente',
            root: 'Organisation root',
            update: 'Editer',
            creation_success: "Organisation créée avec success",
            update_success: "Organisation mise à jour avec success",
            site_draw_on_map: "Cliquez ci-dessous pour dessiner le périmètre autour du site de l'organisation",
            site_formatted_address: "Adresse du site",
            site_boundary_radius: "Rayon du périmètre",
            site_latitude: "Latitude",
            site_longitude: "Longitude",

        },
        root_organization: {
            sections: {
                instructions: "Instructions",
                general: 'Général',
                appConf: 'Configuration de l\'application',
                theme: 'Thème',
                invoicing: "Facturation",
                data_storage: "Stockage des données",
                notifications: "Notifications"

            },
            instructions: {
                intro: "Pour créer une organisation racine, assurez-vous que les étapes requises ci-dessus sont respectées:",
                required_steps_1: "1. Dans mandrill, Ticatag doit authentifier le domaine du client pour pouvoir envoyer des emails avec l'adresse du client comme expéditeur.",
                required_steps_1_1: "1.1 Se connecter sur mandrill :https://mandrillapp.com/",
                required_steps_1_2: "1.2 Cliquer sur 'Settings' dans le menu à gauche",
                required_steps_1_3: "1.3 Cliquer sur 'Domains' dans le menu en haut de la page, puis 'Sending Domains'",
                required_steps_1_4: "1.4 Saisir le nom de domain du client (ex. timcod.com) dans la barre d'ajout à gauche de la page, puis cliquer sur 'Add'",
                required_steps_1_5: "1.5 Saisir le nom de domain du client (ex. timcod.com) dans la barre de recherche cette fois, puis cliquer sur la loupe pour rechercher",
                required_steps_1_6: "1.6 Dans cette nouvelle ligne créée, cliquer sur 'View details' dans la colonne de 'DKIM Settings'",
                required_steps_1_7: "1.7 Vous trouverez les instructions à envoyer au client avec les valeurs DKIM. Vous pouvez également faire une capture d'écran et l'envoyer au client pour compléter la procédure.",
                required_steps_2: "2. Dans OVH, une entrée de type cname doit être créée pour rediriger l'url de l'application vers la distribution cloud front du site web :",
                required_steps_2_1: "2.1 Se connecter sur OVH : 'https://www.ovh.com/manager/#/web/domain/ticatag.com/zone'",
                required_steps_2_2: "2.2 Vous devez être sur la page 'Web Cloud' -> 'Zone Dns'",
                required_steps_2_3: "2.3 Cliquer sur 'Ajouter une entrée' dans le menu",
                required_steps_2_4: "2.4 Sélectionner le type de champ DNS : 'CNAME'",
                required_steps_2_5: "2.5 Saisir le Sous-domaine du client (ex. ppd1-timcod-connect)",
                required_steps_2_6: "2.6 Saisir la Cible qui est l'url de la distribution cloudfront (ex. d2bpq0m9drdksq.cloudfront.net.)",
                required_steps_2_7: "2.7 Cliquer sur 'Suivant', puis 'Valider",
                required_steps_3: "3. Dans le code de l'infrastructure, ajouter une ligne de nom de domain de la root organisation:",
                required_steps_3_1: "3.1 Allez dans le dossier du code",
                required_steps_3_2: "3.2 Allez dans le ficher : 'cdk/lib/persistent-stack/persistent-stack.ts' ",
                required_steps_3_3: "3.3 Dans la ligne de creation de la distribution cloud front de connect : 'this.createCloudFrontDistribution(........)'",
                required_steps_3_4: "3.4 Dans le dernier paramètre de la fonction en forme de liste, ajouter l'url de l'application (ex. `${props.prefix}-timcod-connect.ticatag.com`)",
                required_steps_3_5: "3.5 Dans le terminal, en se positionnant dans le dossier /cdk:",
                required_steps_3_6: "-----> Pour voir les changements sur la stack, executer la commande : APP_PREFIX=ppd1 yarn cdk diff --profile ...",
                required_steps_3_7: "-----> Pour déployer, executer la commande : APP_PREFIX=ppd1 yarn cdk deploy --profile ....",
                required_steps_4: "4. Dans la base de données, ajouter le l'adresse de redirection:",
                required_steps_5: " 5. Ajouter firebase analytics : ",
                required_steps_5_1: "Aller sur https://console.firebase.google.com/",
                required_steps_5_2: "Créer un projet",
                required_steps_5_3: "Créer une application web",
                required_steps_5_4: "Enregistrer les clés pour créer l'organisation racine",

            },
            app_title: 'Titre de l\'application',
            contact_page_url: "URL de la page de contact",
            admin_email: "Adresse email de l'administrateur",
            name: 'Nom',
            company_url: 'URL de l\'entreprise',
            app_url1: 'URL 1 de l\'application',
            app_url2: 'URL 2 de l\'application',
            app_url3: 'URL 3 de l\'application',
            firebase_api_key: 'Clé API Firebase',
            firebase_auth_domain: 'Domaine d\'authentification Firebase',
            firebase_project_id: 'ID de projet Firebase',
            firebase_storage_bucket: 'Bucket de stockage Firebase',
            firebase_messaging_sender_id: 'ID d\'expéditeur de messages Firebase',
            firebase_app_id: 'ID d\'application Firebase',
            firebase_measurement_id: 'ID de mesure Firebase',
            theme_light_primary_main: 'Couleur principale de thème clair (principale)',
            theme_light_primary_dark: 'Couleur principale de thème clair (sombre)',
            theme_light_primary_light1: 'Couleur principale de thème clair (clair 1)',
            theme_light_primary_light2: 'Couleur principale de thème clair (clair 2)',
            theme_light_primary_light3: 'Couleur principale de thème clair (clair 3)',
            theme_light_background_default: 'Fond de thème clair par défaut',
            theme_light_background_paper: 'Fond de thème clair (papier)',
            theme_light_background_appframe: 'Fond de cadre d\'application de thème clair',
            theme_dark_primary_main: 'Couleur principale de thème sombre (principale)',
            theme_dark_primary_dark: 'Couleur principale de thème sombre (sombre)',
            theme_dark_primary_light1: 'Couleur principale de thème sombre (clair 1)',
            theme_dark_primary_light2: 'Couleur principale de thème sombre (clair 2)',
            theme_dark_primary_light3: 'Couleur principale de thème sombre (clair 3)',
            theme_dark_background_default: 'Fond de thème sombre par défaut',
            theme_dark_background_paper: 'Fond de thème sombre (papier)',
            theme_dark_background_appframe: 'Fond de cadre d\'application de thème sombre',
            background_image1: 'L\'image de fond 1',
            background_image2: 'L\'image de fond 2',
            background_image3: 'L\'image de fond 3',
            background_image4: 'L\'image de fond 4',
            background_image5: 'L\'image de fond 5',
            background_image6: 'L\'image de fond 6',
            connect_header_logo: 'Logo de l\'en-tête Connect',
            connect_favicon_logo: 'Logo du favicon Connect',
            connect_icon96x96_png: 'Icone 96x96 Connect',
            connect_icon180x180_png: 'Icone 180x180 Connect',
            connect_icon192x192_png: 'Icone 192x192 Connect',
            connect_icon512x512_png: 'Icone 512x512 Connect',
            auth_page_logo: "Logo de la page d'authentification",
            invoice_number_prefix: 'Préfixe du numéro de facture',
            invoice_issuer_name: 'Nom de l\'émetteur',
            invoice_issuer_address: 'Adresse de l\'émetteur (ex. 5 Avenue Anatole - 75007 - Paris - France )',
            invoice_issuer_phone_number: 'Numéro de téléphone de l\'émetteur',
            invoice_issuer_email: 'Adresse e-mail de l\'émetteur',
            invoice_issuer_web_site: 'Site web de l\'émetteur',
            invoice_logo: 'Logo de la facture',
            invoice_issuer_description1: 'Description de l\'émetteur 1 (siret ex. "Siret: 123456789")',
            invoice_issuer_description2: 'Description de l\'émetteur 2',
            invoice_issuer_description3: 'Description de l\'émetteur 3',
            invoice_footer_description1: 'Description du pied de page 1',
            invoice_footer_description2: 'Description du pied de page 2',
            invoice_footer_description3: 'Description du pied de page 3',
            invoice_issuer_vat_number: 'Numéro de TVA',
            subscription_plans_currency: 'Devise des plans d\'abonnement',
            platform_fee_tax_percent: 'TVA appliquer aux frais de service plateforme',
            events_ttl_months: "Mois d'expiration des événements",
            notification_sender_email: "Adresse email de l'expéditeur ",
            notification_sender_name: "Nom de l'expéditeur",
            notification_email_signature1: "Signature 1",
            notification_email_signature2: "Signature 2",
            notification_email_signature3: "Signature 3",
            notification_email_logo: "Logo de l\'email",



        },
        organizations: {
            page_titles: {
                organizations: "Organisations",
                create: "Créer une organisation",
                edit: "Editer une organisation"
            },
            create: "Création d'une organisation",
            name: 'Organisation |||| Organisations',
            edit: 'Editer une organization',
        },
        root_organizations: {
            browse_root_organization: "Consulter la root organization",
            create: "Création d'une root organisation",
            name: 'Root organisation |||| Root organisations',
            edit: 'Editer une root organization'
        },
        invoices: {
            name: 'Facture |||| Factures',
            date: 'Date',
            description: 'Description',
            number: 'Référence',
            price: 'Prix',
            download: 'Télécharger',
            click_button_bellow_to_download_you_invoice_number: "Cliquer sur le bouton ci-dessous pour télécharger votre facture : %{invoice_number}",
            period: "Période",
            currency: "Devise",
            product_label: 'Libellé',
            product_description1: 'Description 1',
            product_description2: 'Description 2',
            price_tax_included: "Prix TTC",
            tax_amount: "TVA",
            customer: "Client"
        },
        app_owner_invoices: {
            fields: {
                invoice_number: "Numéro de facture",
                creation_date: "Date de creation",
                product_label: "Label",
                root_organization: "Root organization",
                period: "Période",
                total_amount: "Montant total TTC",
                invoices_details_total_amount: "Montant payé par le client TTC",
                invoices_details_total_amount_without_tax: "Montant payé par le client HT",
                invoices_details_total_net_root_org: "Montant pour l'organisation racine HT",
                invoices_details_total_fee_amount_without_tax: "Montant des frais de plateforme HT",
                status: "Statut",
                payment_date: "Date de paiement"
            },
            status: {
                draft: "Brouillon",
                validated: "Validée",
                paid: "Payée"
            },
            name: 'Facture de la plateforme |||| Factures de la plateforme',
            download: 'Télécharger',
            validate: "Valider",
            pay: "Payer"
        },
        product: {
            name: 'Nom du produit',
            release: 'Date de la version',
            versionNumber: 'Numero de version',
            patch: 'Numero du patch',
        },
        profile: 'Profil Utilisateur',
        role: {
            owner: 'Propriétaire',
            member: 'Membre',
            admin: 'Administrateur',
            superadmin: 'Super Administrateur',
        },
        shock: {
            head: 'Choc',
            last: 'Valeur',
            date: 'Date',
            detected: 'Détecté',
            clear: 'Non détécté',
        },
        measures: {
            name: 'Mesure |||| Mesures',
            sensor: "Capteur",
            sensorNone: 'Non',
            displayedMeasures: "Mesures affichées",
            no_measures_of_this_type: "Aucune mesure de ce type n'a été remontée par la balise",
            sensor5: '5 dernières mesures',
            sensor25: '25 dernières mesures',
            sensor50: '50 dernières mesures',
            sensor100: '100 dernières mesures',
            sensorYesterday: 'Hier',
            sensorToday: `Aujourd'hui`,
            customized: "Personnalisée",
            temphum: 'Température/Humidité',
            searchOption: "Option de recherche",
            timestamp: 'Date',
            history: 'Consulter l\'historique des mesures',
            status: 'Etat',
            date: 'Date',
            water_leak_status_off: 'Off',
            water_leak_status_on: 'On',
            door_status_open: 'Ouverte',
            door_status_closed: 'Fermé',
            shock_status_clear: 'Non détecté',
            shock_status_detected: 'Détecté',
            presence_status_detected: 'Détectée',
            presence_status_none: 'Non détéctée',
            battery_status_status_good: 'Bon',
            battery_status_status_bad: 'Mauvais',
            light_status_status_on: 'On',
            light_status_status_off: 'Off',
            button_status_click: 'Click',
            button_status_double_click: 'Double click',
            button_status_hold: 'Appui long',
            motion_status_still: 'En arrêt',
            motion_status_moving: 'Mouvement détectée',
        },
        plans: {
            name: 'Plan |||| Plans',
        },
        subscriptions: {
            accept_tc: "Merci d'accepter les conditions générales de vente",
            add_card_success: 'Moyen de paiement enregistré',
            add_card_error: 'Merci de vérifier vos informations de paiement',
            add_fop_error: "Une erreur est survenue lors de l'enregistrement de votre carte. Veuillez réessayer",
            description: "Vous serez prélevé tous les mois sur votre carte de crédit",
            charged_now: 'Montant facturé : %{amount} %{currency_symbol}',
            enter_voucher: 'Entrez le numéro inscrit sur votre carte',
            fields: {
                active: 'Active',
                device: {
                    name: 'Nom de la balise',
                },
                end_date: 'Date de fin',
                plan: {
                    description: 'Offre',
                    type: 'Type',
                    title: 'Titre',
                },
                start_date: 'Date de début',
                status: 'Statut',
            },
            labels: {
                active: 'Actif',
            },
            statuses: {
                active: 'Actif',
                unpaid: 'Impayé',
                past: 'Passé',
                canceled: 'Annulé',
                unknown: "Inconnu"
            },
            states: {
                onGoing: 'En cours',
                comingNext: 'A venir',
                unpaid: 'En attente de la prochaine tentative de paiement',
            },
            name: 'Abonnement |||| Abonnements',
            add_subscription: 'Ajouter un abonnement',
            create: "Créer un abonnement",
            add_subscription_as_super_admin_tooltip: "Ajouter un abonnement en tant que Super Admin",
            add_subscription_as_super_admin: "S.A : Ajouter un abonnement",
            cancel_subscription: 'Annuler l\'abonnement',
            cancel_subscription_confirmation: "Êtes-vous sûr de vouloir annuler votre abonnement ?",
            subscription_canceled: 'Abonnement annulé',
            no_subscription_found: 'Aucun abonnement associé à la balise',
            subscription_already_attached_to_device: "Abonnement déjà attaché à votre balise",
            subscription_success: 'Votre abonnement a bien été pris en compte',
            subscription_creating: 'Votre abonnement est en cours de création...',
            subscription_creation_error: "Une erreur s'est produite lors de la création de votre abonnement ! Vous allez être redirigé vers la page de contact support... ",
            subscription_recognizing_error: "Une erreur s'est produite lors de la recherche des abonnements attachés à cette balise! Merci de contacter le support... ",
            no_selected_plan: 'Aucune offre sélectionnée',
            postpaid_name: 'Abonnement mensuel',
            prepaid: 'Prépayée',
            prepaid_name: 'Abonnement prépayée - %{quantity} mois',
            prepaid_offer: 'Recharge en ligne',
            prepaid_offer_description: 'Achetez une formule prépayée',
            selected_offer: 'Offre sélectionnée',
            tc:
                'J\'accepte <a href="http://mytifiz.com/legal/cgv" target="_blank"> les conditions d\'utilisation</a> et les conditions relatives à l\'abonnement ci-dessus.',
            tc_postpaid:
                "Vous serez facturé %{amount} %{currency_symbol} mensuellement jusqu'à ce que vous décidiez de résilier votre abonnement. Les sommes déjà prélevées ne seront pas remboursées lors de la résiliation. Des frais de résiliation seront appliquées si vous résiliez dans les douze premiers mois. Vos données de paiement sont chiffrées et sécurisées. Tous les montants sont indiqués en %{currency_code}.",
            tc_prepaid:
                'Vous serez facturé %{amount} %{currency_symbol}. Les sommes déjà prélevées ne seront pas remboursées. A échéance, votre abonnement ne sera pas reconduit automatiquement. Vos données de paiement sont chiffrées et sécurisées. Tous les montants sont indiqués en %{currency_code}.',
            voucher: 'Recharge par carte prépayée',
            voucher_description: 'Si vous avez une carte prépayée (code) pour activer votre balise',
            voucher_already_burnt: 'Code déjà utilisé',
            voucher_code: 'Code',
            voucher_name: 'Recharge par carte prépayée - %{quantity} mois',
            voucher_not_found: 'Code inconnu',
            voucher_title: 'N° Carte : %{voucher_code}',
            voucher_length_must_be_gt_10: "Le code de la recharge doit être supérieur à 10 chiffres"

        },
        temperature: {
            head: 'Température',
            value: 'Valeur',
            date: 'Dernier relevé',
        },
        theme: 'Thème',
        timechart: 'Historisation des températures',
        time_range: 'Zoom',
        title: {
            configuration: {
                list: 'Configuration',
            },
            devices: {
                list: 'Balises',
                editer: 'Editer Balise',
                create: 'Créer Balise',
            },
            geofences: {
                list: 'Barrières virtuelles',
                editer: 'Editer Barrières virtuelles',
                create: 'Créer Barrières virtuelles',
            },
            locations: {
                list: 'Positions',
                editer: 'Historique Positions',
            },
            notifications: {
                list: 'Alertes',
                editer: 'Editer Alerte',
                create: 'Créer Alerte',
            },
            organizations: {
                list: 'Organisations',
                editer: 'Editer Organisations',
                create: 'Créer Organisation',
            },
            measures: {
                list: 'Mesures',
                history: 'Historique des mesures',
                create: 'Créer Mesure',
            },
            users: {
                list: 'Utilisateurs',
                editer: 'Editer Utilisateurs',
                create: 'Créer Utilisateur',
            },
        },
        upload: 'Choisissez votre fichier ...',
        account: {
            edit: 'Profil',
            personal_information: "Informations personnelles"
        },
        users: {
            invite_action: 'Inviter un utilisateur',
            invite_success: "L'utilisateur a été invité avec succès, il recevra un email pour suivre le la procédure de création de son compte.",
            creation_success: "L'utilisateur a été créé avec succès",
            confirm_edit_organization_message: "Le changement d'organisation de l'utilisateur entraînera le transfert des balises dont il est propriétaire à la nouvelle organisation.",
            create: 'Créer un utilisateur',
            edit: 'Edition d\'un utilisateur',
            delete: "Enlever l'utilisateur de votre organisation",
            email: 'Email',
            cemail: 'Confirmation email',
            password: 'Mot de passe',
            cpassword: 'Confirmation mot de passe',
            company_name: 'Entreprise',
            customer: 'Client',
            first_name: 'Prénom',
            phoneNumber: 'Numéro de téléphone',
            birth_date: 'Date de naissance',
            invite: "Envoyer l'invitation",
            last_name: 'Nom',
            name: 'Utilisateur |||| Utilisateurs',
            role: 'Rôle',
            update: 'Editer',
            user: 'Utilisateur',
            users: 'Utilisateurs',
            language: 'Langue',
            profile: 'Profil',
            passwords_not_match: 'Les mots de passe ne correspondent pas',
            invalid_password: 'Le mot de passe doit comporter au moins 6 caractères',
            password_changed: 'Mot de passe mise à jour',
            change_my_password: "Changer mon mot de passe",
            email_changed: 'Email mis à jour',
            emails_not_match: 'Les emails ne correspondent pas',
            invalid_email: 'Email invalide',
            lang: {
                fr: 'Français',
                en: 'Anglais',
            }
        }, contact: {
            name: 'contact |||| contact ',
            delete: 'Supprimer la barrière virtuelle',
            create: "Création d'une barrière virtuelle",
            edit: "Edition d'une barrière virtuelle",
            center: 'Centre',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alerte',
            fields: {
                radius: 'Rayon',
                address: 'Adresse',
                on_entry: 'sur entrée',
                on_exit: 'sur sortie',
                active: 'Actif',
            },
            draw: {
                save_modifications: 'Enregistrer les modifications',
                cancel_modifications: 'Annuler les modifications',
                select_geofence: 'Sélectionnez les carrés pour modifier le geofence',
                click_cancel: 'Cliquez sur annuler pour rétablir les modifications',
            },
            device_search: 'Recherchez une balise',
            enter_address: 'Entrez une adresse',
            no_device_selected: 'Vous devez sélectionnez une balise',
            no_center_defined: "Vous n'avez pas défini le centre du geofence",
        },
        help: {
            name: 'Aide |||| Aide ',
            faq: "FAQ",
            delete: 'Supprimer la barrière virtuelle',
            create: "Création d'une barrière virtuelle",
            edit: "Edition d'une barrière virtuelle",
            center: 'Centre',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alerte',
            fields: {
                radius: 'Rayon',
                address: 'Adresse',
                on_entry: 'sur entrée',
                on_exit: 'sur sortie',
                active: 'Actif',
            },
            draw: {
                save_modifications: 'Enregistrer les modifications',
                cancel_modifications: 'Annuler les modifications',
                select_geofence: 'Sélectionnez les carrés pour modifier le geofence',
                click_cancel: 'Cliquez sur annuler pour rétablir les modifications',
            },
            device_search: 'Recherchez une balise',
            enter_address: 'Entrez une adresse',
            no_device_selected: 'Vous devez sélectionnez une balise',
            no_center_defined: "Vous n'avez pas défini le centre du geofence",
        },
        hooks: {
            browse_hook: "Consulter le webhook",
            create: 'Création d\'un webhook',
            edit: 'Edition d\'un webhook',
            name: 'Webhook |||| Webhooks',
            location_changed: 'Position différente',
            new_location: 'Nouvelle position',
            state_changed: 'Changement d\'état',
            button_pressed: 'Appui bouton',
            button_pressed_click: 'Appui bouton - Clic',
            button_pressed_double_click: 'Appui bouton - Double clic',
            button_pressed_hold: 'Appui bouton - Appui long',
            new_co2: "Taux de Co2 - Nouvelle mesure",
            new_temperature: 'Température - Nouvelle mesure',
            new_humidity: 'Humidité - Nouvelle mesure',
            new_door: 'Porte - Nouvel état',
            new_water_leak: 'Détection d\'eau - Nouvel état',
            new_shock: 'Choc - Nouvel état',
            new_rollover: 'Renversement - Nouvel état',
            new_battery_level: 'Niveau de batterie - Nouvelle mesure',
            new_battery_status: 'Batterie - Nouveau status',
            new_motion: 'Déplacement - Nouvel état',
            new_notification_log: "Journal d'alerte - Nouvelle alerte",
            new_pressure: "Pression - Nouvelle mesure",
            new_luminosity: "Luminosité - Nouvelle mesure",
            new_light_status: "État de la lumière - Nouvelle mesure",
            new_aqi: "Indice de qualité de l'air - Nouvelle mesure",
            new_mox: "Mox - Nouvelle mesure",
            new_alarm: "Alarme - Nouvelle alarme",
            new_presence: "Présence - Nouvel état",
            new_total_odometer: "Compteur kilométrique total - Nouvelle mesure",
            new_high_g: "High G - Nouvelle mesure",
            new_formaldehyde: "Formaldéhyde - Nouvelle mesure",
            new_pm10: "Particules PM10 - Nouvelle mesure",
            new_pm2p5: "Particules PM2P5 - Nouvelle mesure",
            new_pm1: "Particules PM1 - Nouvelle mesure",
            new_tvoc: "TVOC - Nouvelle mesure",
            new_ammonia: "Amoniac - Nouvelle mesure",
            new_benzen: "Benzen - Nouvelle mesure",
            new_noise: "Bruit - Nouvelle mesure",
            device: {
                warning: 'Laisser vide pour appliquer à toutes les balises'
            },
            version: {
                warning: 'Seul la création de webhook v3 est désormais autorisé',
            },
            fields: {
                name: "Nom du webhook",
                target_url: "Url",
                version: "Version",
                events: "Evénements",
                active: 'Actif',
                device: 'Balise',
                organization: "Organisation",
                applyOn: "Appliquer sur",
                applyOnOneDevice: "Une balise",
                applyOnOneOrganization: "Une organisation",
                auth: "Authentification",
                auth_type: "Type",
                auth_username: 'Username',
                auth_password: 'Password',

            },
            info_about_hooks: {
                title: "Nouveautés de la V3 :",
                message1: "Les positions GPS peuvent être renvoyées via deux événements :",
                message2: "- Nouvelle position: cet événement vous envoie la position de la balise à chaque message",
                message3: "- Position différente: cet événement vous envoie la position de la balise uniquement si elle est différente de la précédente (avec une précision de 20 mètres)."
            }
        }
    },
}
