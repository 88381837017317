import React from 'react'
import { Box } from '@mui/material';
import { RO_LOGO_URL } from '../pages/configuration/actions';





const RoLogoAppBar = () => {
    const imageSrc = localStorage.getItem(RO_LOGO_URL) || "https://images.crunchbase.com/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/v1446282194/zu7wapifznbz5nnvcdag.png"
    return (
        <Box
            sx={{

                width: "224px",
                height: "53px",
                margin: "0px",
                padding: "0px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }}
        >
            <img
                src={imageSrc}
                alt="app-logo"
                style={{
                    width: "224px",
                    height: "53px",
                    padding: 1,
                }}

            />

        </Box>)

}


export default RoLogoAppBar;




